import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [newUser, setNewUser] = useState({ email: '', senha: '', role: 'user' });
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchProjects();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/users`, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${API_URL}/projetos`, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      setProjects(response.data);
    } catch (error) {
      console.error('Erro ao buscar projetos:', error);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/register`, newUser, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      setNewUser({ email: '', senha: '', role: 'user' });
      fetchUsers();
    } catch (error) {
      console.error('Erro ao criar usuário:', error);
    }
  };

  const handleUserSelect = async (user) => {
    setSelectedUser(user);
    try {
      const response = await axios.get(`${API_URL}/projetos-usuario/${user.id}`, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      setSelectedProjects(response.data.map(project => project.id));
    } catch (error) {
      console.error('Erro ao buscar projetos do usuário:', error);
      setSelectedProjects([]);
    }
  };

  const handleProjectToggle = (projectId) => {
    setSelectedProjects(prev => 
      prev.includes(projectId) 
        ? prev.filter(id => id !== projectId)
        : [...prev, projectId]
    );
  };

  const handleUpdateUser = async () => {
    if (!selectedUser) return;
    try {
      await axios.put(`${API_URL}/users/${selectedUser.id}`, {
        role: selectedUser.role,
        projetos: selectedProjects
      }, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      fetchUsers();
      alert('Usuário atualizado com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      alert('Erro ao atualizar usuário. Por favor, tente novamente.');
    }
  };

  const handleExportDatabase = async () => {
    try {
      const response = await axios.get(`${API_URL}/export-database`, {
        responseType: 'blob',
        headers: { Authorization: localStorage.getItem('token') }
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'experimentos.db');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Erro ao exportar banco de dados:', error);
      alert('Erro ao exportar banco de dados. Por favor, tente novamente.');
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Painel de Administração</h2>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Criar Novo Usuário</h3>
        <form onSubmit={handleCreateUser} className="space-y-2">
          <input
            type="email"
            placeholder="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({...newUser, email: e.target.value})}
            className="w-full p-2 border rounded"
          />
          <input
            type="password"
            placeholder="Senha"
            value={newUser.senha}
            onChange={(e) => setNewUser({...newUser, senha: e.target.value})}
            className="w-full p-2 border rounded"
          />
          <select
            value={newUser.role}
            onChange={(e) => setNewUser({...newUser, role: e.target.value})}
            className="w-full p-2 border rounded"
          >
            <option value="user">Usuário</option>
            <option value="admin">Administrador</option>
          </select>
          <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Criar Usuário</button>
        </form>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Usuários Existentes</h3>
        <ul className="space-y-2">
          {users.map(user => (
            <li key={user.id} className="p-2 border rounded">
              <button onClick={() => handleUserSelect(user)} className="w-full text-left">
                {user.email} - Role: {user.role}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {selectedUser && (
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-2">Editar Usuário: {selectedUser.email}</h3>
          <div className="space-y-2">
            <select
              value={selectedUser.role}
              onChange={(e) => setSelectedUser({...selectedUser, role: e.target.value})}
              className="w-full p-2 border rounded"
            >
              <option value="user">Usuário</option>
              <option value="admin">Administrador</option>
            </select>
            <div>
              <h4 className="font-semibold">Projetos Associados:</h4>
              {projects.map(project => (
                <label key={project.id} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedProjects.includes(project.id)}
                    onChange={() => handleProjectToggle(project.id)}
                    className="mr-2"
                  />
                  {project.nome}
                </label>
              ))}
            </div>
            <button onClick={handleUpdateUser} className="w-full p-2 bg-green-500 text-white rounded">
              Atualizar Usuário
            </button>
          </div>
        </div>
      )}
      <button
        onClick={handleExportDatabase}
        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mt-4"
      >
        Exportar Banco de Dados
      </button>
    </div>
  );
};

export default AdminPanel;