// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-content h1, .markdown-content h2, .markdown-content h3 {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .markdown-content h1 {
    font-size: 1.5em;
  }
  
  .markdown-content h2 {
    font-size: 1.3em;
  }
  
  .markdown-content h3 {
    font-size: 1.1em;
  }
  
  .markdown-content p {
    margin-bottom: 0.5em;
  }
  
  .markdown-content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 0.5em;
  }
  
  .markdown-content ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin-bottom: 0.5em;
  }
  
  .markdown-content li {
    margin-bottom: 0.5em;
  }
  
  .markdown-content strong {
    font-weight: bold;
  }
  
  .markdown-content em {
    font-style: italic;
  }`, "",{"version":3,"sources":["webpack://./src/css/markdown-styles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,oBAAoB;EACtB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,oBAAoB;EACtB;;EAEA;IACE,wBAAwB;IACxB,kBAAkB;IAClB,oBAAoB;EACtB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".markdown-content h1, .markdown-content h2, .markdown-content h3 {\n    font-weight: bold;\n    margin-top: 1em;\n    margin-bottom: 0.5em;\n  }\n  \n  .markdown-content h1 {\n    font-size: 1.5em;\n  }\n  \n  .markdown-content h2 {\n    font-size: 1.3em;\n  }\n  \n  .markdown-content h3 {\n    font-size: 1.1em;\n  }\n  \n  .markdown-content p {\n    margin-bottom: 0.5em;\n  }\n  \n  .markdown-content ul {\n    list-style-type: disc;\n    padding-left: 20px;\n    margin-bottom: 0.5em;\n  }\n  \n  .markdown-content ol {\n    list-style-type: decimal;\n    padding-left: 20px;\n    margin-bottom: 0.5em;\n  }\n  \n  .markdown-content li {\n    margin-bottom: 0.5em;\n  }\n  \n  .markdown-content strong {\n    font-weight: bold;\n  }\n  \n  .markdown-content em {\n    font-style: italic;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
