import React, { useState, useEffect } from 'react';
import axios from 'axios';
import KanbanBoard from './components/KanbanBoard';
import Auth from './components/Auth';
import { useProjects } from './hooks/useProjects';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header';
import AdminPanel from './components/AdminPanel';
import ProjetosPage from './components/ProjetosPage';
import AcompanhamentosETodos from './components/AcompanhamentosETodos';

const API_URL = process.env.REACT_APP_API_URL;

function App() {
  const { saveProject } = useProjects();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProjects, setUserProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState('');
  const [currentSection, setCurrentSection] = useState('dashboard');
  const [columns, setColumns] = useState({
    backlog: [],
    todo: [],
    running: [],
    analysing: [],
    done: []
  });
  const [projects, setProjects] = useState([]);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUserRole = localStorage.getItem('userRole');
    if (token && storedUserRole) {
      // Verificar se o token é válido
      axios.get(`${API_URL}/verify-token`, {
        headers: { Authorization: token }
      })
      .then(response => {
        if (response.data.valid) {
          setIsLoggedIn(true);
          setUserRole(storedUserRole);
          if (storedUserRole === 'admin') {
            fetchProjects();
          } else {
            fetchUserProjects();
          }
        } else {
          handleLogout();
        }
      })
      .catch(error => {
        console.error('Erro ao verificar token:', error);
        handleLogout();
      });
    }
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserProjects([]);
    setCurrentProject('');
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
  };

  useEffect(() => {
    if (isLoggedIn && userRole === 'admin') {
      fetchProjects();
    }
  }, [userRole, isLoggedIn]);

  const fetchUserProjects = async () => {
    try {
      const response = await axios.get(`${API_URL}/projetos-usuario`, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      setUserProjects(response.data);
      if (response.data.length === 1) {
        setCurrentProject(response.data[0].id);
        handleProjectChange({ target: { value: response.data[0].id } });
      }
    } catch (error) {
      console.error('Erro ao buscar projetos do usuário:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
    }
  };

  const handleLogin = async (token, role, projetos) => {
    console.log('Login realizado. Role:', role);
    setIsLoggedIn(true);
    setUserRole(role);
    localStorage.setItem('token', token);
    localStorage.setItem('userRole', role);
    if (role === 'admin') {
      await fetchProjects();
    } else {
      await fetchUserProjects();
    }
    if (projetos) {
      setUserProjects(projetos);
    }
  };

  const handleProjectChange = async (e) => {
    const newProjectId = e.target.value;
    setCurrentProject(newProjectId);
    if (newProjectId) {
      try {
        const response = await axios.get(`${API_URL}/experimentos?projeto_id=${newProjectId}`);
        const projectColumns = {
          backlog: [],
          todo: [],
          running: [],
          analysing: [],
          done: []
        };
        response.data.forEach(exp => {
          const estagio = exp.estagio_funil || 'backlog';
          if (projectColumns[estagio]) {
            projectColumns[estagio].push(exp);
          } else {
            console.warn(`Estágio desconhecido: ${estagio}`);
            projectColumns.backlog.push(exp);
          }
        });
        setColumns(projectColumns);
      } catch (error) {
        console.error('Erro ao carregar experimentos do projeto:', error);
      }
    } else {
      setColumns({
        backlog: [],
        todo: [],
        running: [],
        analysing: [],
        done: []
      });
    }
  };

  const handleAddProject = async () => {
    const projectName = prompt('Digite o nome do novo projeto:');
    if (projectName) {
      try {
        const response = await axios.post(`${API_URL}/projetos`, { nome: projectName });
        setProjects([...projects, response.data]);
        setCurrentProject(response.data.id);
        alert('Novo projeto criado com sucesso!');
      } catch (error) {
        console.error('Erro ao criar projeto:', error);
        alert('Erro ao criar projeto. Por favor, tente novamente.');
      }
    }
  };

  const handleDeleteProject = async () => {
    if (currentProject && window.confirm(`Tem certeza que deseja deletar o projeto "${currentProject}"?`)) {
      try {
        await axios.delete(`${API_URL}/projetos/${currentProject}`);
        setProjects(prevProjects => prevProjects.filter(project => project.id !== currentProject));
        setCurrentProject('');
        setColumns({
          backlog: [],
          todo: [],
          running: [],
          analysing: [],
          done: []
        });
        alert('Projeto deletado com sucesso!');
        await fetchProjects(); // Recarregar a lista de projetos
      } catch (error) {
        console.error('Erro ao deletar projeto:', error);
        alert('Erro ao deletar projeto. Por favor, tente novamente.');
      }
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${API_URL}/projetos`, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      console.log('Projetos carregados:', response.data);
      setProjects(response.data);
      if (userRole === 'admin') {
        setUserProjects(response.data);
      } else {
        await fetchUserProjects();
      }
    } catch (error) {
      console.error('Erro ao buscar projetos:', error);
    }
  };

  const handleSectionChange = (section) => {
    setCurrentSection(section);
  };

  if (!isLoggedIn) {
    return <Auth onLogin={handleLogin} />;
  }

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <Header userRole={userRole} onLogout={handleLogout} />
        <main className="w-full mx-auto py-6 sm:px-6 lg:px-8">
          <Routes>
            <Route path="/admin" element={<AdminPanel />} />
            <Route path="/projetos" element={<ProjetosPage />} />
            <Route path="/" element={
              <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
                <div className="w-full px-4">
                  <div className="mb-4">
                    <select 
                      value={currentProject} 
                      onChange={handleProjectChange}
                      className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md shadow-sm appearance-none bg-white"
                      style={{
                        backgroundImage: "url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E')",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right 0.5rem center",
                        backgroundSize: "1.5em 1.5em",
                        paddingRight: "2.5rem"
                      }}
                    >
                      <option value="">Selecione um projeto</option>
                      {(userRole === 'admin' ? projects : userProjects).map(project => (
                        <option key={project.id} value={project.id}>{project.nome}</option>
                      ))}
                    </select>
                  </div>

                  {currentProject && (
                    <div className="mb-4">
                      <button
                        onClick={() => handleSectionChange('dashboard')}
                        className={`mr-2 px-4 py-2 rounded ${currentSection === 'dashboard' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                      >
                        Dashboard experimento
                      </button>
                      <button
                        onClick={() => handleSectionChange('acompanhamentos')}
                        className={`px-4 py-2 rounded ${currentSection === 'acompanhamentos' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                      >
                        Acompanhamento & to-dos
                      </button>
                    </div>
                  )}

                  {currentSection === 'dashboard' ? (
                    <KanbanBoard
                      columns={columns}
                      setColumns={setColumns}
                      currentProject={currentProject}
                      saveProject={saveProject}
                    />
                  ) : (
                    <AcompanhamentosETodos projectId={currentProject} />
                  )}
                </div>
              </div>
            } />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;