export const canalOptions = [
  { value: "vendas_prospecao", label: "Vendas", description: "Vendas - Prospecção Ativa" },
  { value: "anuncios_sociais", label: "Anúncios Sociais", description: "Anúncios em redes sociais e display (META e cia)" },
  { value: "anuncios_busca", label: "Anúncios Busca", description: "Anúncios em motor de busca (Adwords e cia)" },
  { value: "anuncios_offline", label: "Anúncios Offline", description: "Anúncios Offlines" },
  { value: "marketing_conteudo", label: "Marketing de Conteúdo", description: "Marketing de Conteúdo" },
  { value: "email_marketing", label: "E-mail Marketing", description: "E-mail Marketing" },
  { value: "seo", label: "SEO", description: "SEO - Otimização para sites de busca" },
  { value: "desenvolvimento_negocio", label: "Desenvolvimento de Negócio", description: "Desenvolvimento de Negócio" },
  { value: "eventos_offline", label: "Eventos Offline", description: "Eventos offline" },
  { value: "relacoes_publicas", label: "Relações Públicas", description: "Relações Públicas" },
  { value: "relacoes_publicas_nao_convencionais", label: "RP Não Convencionais", description: "Relações Públicas não convencionais" },
  { value: "engenharia_marketing", label: "Engenharia como Marketing", description: "Engenharia como Marketing" },
  { value: "blogs_nicho", label: "Blogs de Nicho", description: "Blogs de Nicho" },
  { value: "afiliados", label: "Afiliados", description: "Afiliados" },
  { value: "plataformas_existentes", label: "Plataformas Existentes", description: "Plataformas Existentes" },
  { value: "marketing_viral", label: "Marketing Viral", description: "Marketing Viral" },
  { value: "feiras_eventos", label: "Feiras e Eventos", description: "Participação em Feiras e eventos" },
  { value: "palestras", label: "Palestras", description: "Palestras on e offline" },
  { value: "comunidade", label: "Construção de Comunidade", description: "Construção de comunidade" }
];
