import React, { useState } from 'react';
import { calculateDeadlineProgress, calculateRemainingDays, calculateMetricProgress } from '../utils/calculations';
import { canalOptions } from '../utils/canalOptions';

// Este componente ExperimentCard representa um card individual no quadro Kanban.
// Ele exibe informações sobre um experimento e fornece botões para editar, excluir e duplicar o card.

const ExperimentCard = ({ card, onEdit, showDeadlinesAndMetrics, showChannels, columnId }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Calcula o progresso do prazo baseado nas datas de início e fim
  const deadlineProgress = calculateDeadlineProgress(card.data_inicio, card.data_fim);
  
  // Calcula o progresso da métrica baseado nos valores atual e alvo
  const metricProgress = calculateMetricProgress(card.valor_atual, card.valor_alvo, card.is_porcentagem);
  
  // Calcula quantos dias restam até o prazo final
  const remainingDays = calculateRemainingDays(card.data_fim);
  const deadlineText = remainingDays < 0 
    ? <span className="text-red-600 font-semibold">Prazo: Expirado ({Math.abs(remainingDays)} dias)</span>
    : `Prazo: ${remainingDays} dias restantes`;

  const getProgressColor = (progress) => {
    if (progress < 30) {
      return 'bg-gradient-to-r from-red-600 via-red-500 to-red-400';
    } else if (progress < 50) {
      return 'bg-gradient-to-r from-red-400 via-yellow-500 to-yellow-400';
    } else if (progress < 75) {
      return 'bg-gradient-to-r from-yellow-400 via-yellow-300 to-green-300';
    } else {
      return 'bg-gradient-to-r from-green-400 via-green-500 to-green-600';
    }
  };

  return (
    <div 
      className={`bg-white rounded-lg p-4 mb-4 cursor-pointer transition-all duration-200 flex flex-col ${
        isHovered ? 'shadow-xl border-blue-500' : 'shadow-md border-transparent'
      }`}
      onClick={() => onEdit(card)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex-grow">
        <h3 className="text-lg font-semibold mb-2">{card.name}</h3>
        
        {/* Conteúdo principal do card */}
        {columnId === 'backlog' ? (
          // Para cards no backlog, mostrar apenas o título
          <></>
        ) : columnId === 'todo' ? (
          // Para cards no todo, mostrar a data estimada de início
          <div className="mt-2">
            <p className="text-sm text-gray-500">Estimado Iniciar em: {new Date(card.data_inicio).toLocaleDateString()}</p>
          </div>
        ) : (
          // Para outras colunas, mostrar prazos e métricas se habilitado
          showDeadlinesAndMetrics && (
            <>
              {/* Seção de prazo */}
              <div className="mt-2">
                <p className="text-sm">{deadlineText}</p>
                {/* Barra de progresso do prazo */}
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-1">
                  <div 
                    className={`h-2.5 rounded-full ${getProgressColor(100 - deadlineProgress)}`}
                    style={{ width: `${deadlineProgress}%` }}
                  ></div>
                </div>
              </div>

              {/* Seção de métrica */}
              <div className="mt-2">
                <p className="text-sm">
                  {card.metrica}: {card.valor_atual} de {card.valor_alvo}
                  {card.is_porcentagem ? '%' : ''}
                </p>
                {/* Barra de progresso da métrica */}
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-1">
                  <div 
                    className={`h-2.5 rounded-full ${getProgressColor(metricProgress)}`}
                    style={{ width: `${metricProgress}%` }}
                  ></div>
                </div>
              </div>
            </>
          )
        )}
      </div>
      
      {showChannels && (
        <div className="mt-7 -mb-4 -mx-4 bg-blue-500 text-white text-xs px-2 py-1 rounded-bl-lg rounded-br-lg self-end">
          {canalOptions.find(option => option.value === card.canal)?.label || 'Canal não definido'}
        </div>
      )}
    </div>
  );
};

export default ExperimentCard;