import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ userRole, onLogout }) => {
  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img src={process.env.PUBLIC_URL + '/logo512.png'} alt="Logo" className="h-8 w-8 mr-2" />
            <h1 className="text-2xl font-bold text-gray-900">Experimentação</h1>
          </div>
          <nav>
            <div className="flex items-center space-x-4">
              <Link to="/" className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100 transition duration-150 ease-in-out">
                Home
              </Link>
              {userRole === 'admin' && (
                <>
                  <Link to="/admin" className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100 transition duration-150 ease-in-out">
                    Admin
                  </Link>
                  <Link to="/projetos" className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100 transition duration-150 ease-in-out">
                    Projetos
                  </Link>
                </>
              )}
              <button onClick={onLogout} className="px-3 py-2 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out">
                Logout
              </button>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;