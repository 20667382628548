import React from 'react';

const HelpModal = ({ isOpen, onClose, canalOptions }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="help-modal">
      <div className="relative top-20 mx-auto p-5 border w-3/4 max-w-4xl shadow-lg rounded-md bg-white">
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Descrição dos Canais</h3>
          <div className="mt-2 px-7 py-3">
            {canalOptions.map(option => (
              <div key={option.value} className="text-left mb-2">
                <strong>{option.label}:</strong> {option.description}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpModal;