import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const ProjetosPage = () => {
  const [projects, setProjects] = useState([]);
  const [newProjectName, setNewProjectName] = useState('');
  const [users, setUsers] = useState({});

  useEffect(() => {
    fetchProjects();
    fetchUsers();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${API_URL}/projetos`, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      setProjects(response.data);
    } catch (error) {
      console.error('Erro ao buscar projetos:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/users`, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      const usersData = {};
      response.data.forEach(user => {
        usersData[user.id] = user;
      });
      setUsers(usersData);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  const handleAddProject = async () => {
    if (!newProjectName.trim()) return;
    try {
      const response = await axios.post(`${API_URL}/projetos`, { nome: newProjectName }, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      setNewProjectName('');
      fetchProjects();
      alert('Projeto adicionado com sucesso!');
    } catch (error) {
      console.error('Erro detalhado ao adicionar projeto:', error.response?.data || error.message);
      alert(`Erro ao adicionar projeto: ${error.response?.data?.error || error.message}`);
    }
  };

  const handleDeleteProject = async (projectId) => {
    if (window.confirm('Tem certeza que deseja deletar este projeto?')) {
      try {
        await axios.delete(`${API_URL}/projetos/${projectId}`, {
          headers: { Authorization: localStorage.getItem('token') }
        });
        fetchProjects();
      } catch (error) {
        console.error('Erro ao deletar projeto:', error);
      }
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Projetos</h2>
      
      <div className="mb-4">
        <input
          type="text"
          value={newProjectName}
          onChange={(e) => setNewProjectName(e.target.value)}
          placeholder="Nome do novo projeto"
          className="p-2 border rounded mr-2"
        />
        <button
          onClick={handleAddProject}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Adicionar Projeto
        </button>
      </div>

      <ul className="divide-y divide-gray-200">
        {projects.map(project => (
          <li key={project.id} className="py-4">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-lg font-semibold">{project.nome}</h3>
              </div>
              <button
                onClick={() => handleDeleteProject(project.id)}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                Deletar
              </button>
            </div>
            <div className="mt-2">
              <h4 className="font-medium">Usuários associados:</h4>
              <ul className="list-disc list-inside">
                {project.usuarios && project.usuarios.map(userId => (
                  <li key={userId}>{users[userId] ? users[userId].email : 'Usuário desconhecido'}</li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProjetosPage;