export const canalOptions = [
  { value: "vendas_prospecao", label: "Vendas", description: "Vendas - Prospecção Ativa" },
  { value: "anuncios_sociais", label: "Anúncios Redes Sociais", description: "Anúncios em redes sociais e display (META e cia)" },
  { value: "anuncios_busca", label: "Anúncios Busca", description: "Anúncios em motor de busca (Adwords e cia)" },
  { value: "marketing_conteudo", label: "Marketing de Conteúdo", description: "Marketing de Conteúdo" },
  { value: "indicacao", label: "Indicação", description: "Indicação / Referral" },
  { value: "anuncios_offline", label: "Anúncios Offline", description: "Anúncios Offlines" },
  { value: "email_marketing", label: "E-mail Marketing", description: "E-mail Marketing" },
  { value: "seo", label: "SEO", description: "SEO - Otimização para sites de busca" },
  { value: "parceiros", label: "Parceiros", description: "Parceiros" },
  { value: "eventos", label: "Eventos", description: "Eventos" },
  { value: "relacoes_publicas", label: "Relações Públicas", description: "Relações Públicas" },
  { value: "relacoes_publicas_nao_convencionais", label: "RP Não Convencionais", description: "Relações Públicas não convencionais" },
  { value: "engenharia_marketing", label: "Engenharia como Marketing", description: "Engenharia como Marketing" },
  { value: "blogs_nicho", label: "Blogs / Comunidades Nicho", description: "Blogs de Nicho" },
  { value: "afiliados", label: "Afiliados", description: "Afiliados" },
  { value: "plataformas_existentes", label: "Plataformas Existentes", description: "Plataformas Existentes" },
  { value: "marketing_viral", label: "Marketing Viral", description: "Marketing Viral" },
  { value: "comunidade", label: "Construção de Comunidade", description: "Construção de comunidade" },
  { value: "feature_interno", label: "Feature / Produto", description: "Feature / Produto" },
  { value: "outros", label: "Outros", description: "Outros" }
];
