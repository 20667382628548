import React, { useState } from 'react';
import { calculateDeadlineProgress, calculateRemainingDays, calculateMetricProgress } from '../utils/calculations';

// Este componente ExperimentCard representa um card individual no quadro Kanban.
// Ele exibe informações sobre um experimento e fornece botões para editar, excluir e duplicar o card.

const ExperimentCard = ({ card, onEdit, showDeadlinesAndMetrics }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Calcula o progresso do prazo baseado nas datas de início e fim
  const deadlineProgress = calculateDeadlineProgress(card.data_inicio, card.data_fim);
  
  // Calcula o progresso da métrica baseado nos valores atual e alvo
  const metricProgress = calculateMetricProgress(card.valor_atual, card.valor_alvo, card.is_porcentagem);
  
  // Calcula quantos dias restam até o prazo final
  const remainingDays = calculateRemainingDays(card.data_fim);

  const getProgressColor = (progress) => {
    if (progress < 30) {
      return 'bg-gradient-to-r from-red-600 via-red-500 to-red-400';
    } else if (progress < 50) {
      return 'bg-gradient-to-r from-red-400 via-yellow-500 to-yellow-400';
    } else if (progress < 75) {
      return 'bg-gradient-to-r from-yellow-400 via-yellow-300 to-green-300';
    } else {
      return 'bg-gradient-to-r from-green-400 via-green-500 to-green-600';
    }
  };

  return (
    <div 
      className={`bg-white rounded-lg p-4 mb-4 cursor-pointer transition-all duration-200 ${
        isHovered ? 'shadow-xl border-blue-500' : 'shadow-md border-transparent'
      }`}
      onClick={() => onEdit(card)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h3 className="text-lg font-semibold mb-2">{card.name}</h3>
      
      {/* Exiba os prazos e métricas apenas se showDeadlinesAndMetrics for true */}
      {showDeadlinesAndMetrics && (
        <>
          {/* Seção de prazo */}
          <div className="mt-2">
            <p className="text-sm">Prazo: {remainingDays} dias restantes</p>
            {/* Barra de progresso do prazo */}
            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-1">
              <div 
                className={`h-2.5 rounded-full ${getProgressColor(100 - deadlineProgress)}`}
                style={{ width: `${deadlineProgress}%` }}
              ></div>
            </div>
          </div>

          {/* Seção de métrica */}
          <div className="mt-2">
            <p className="text-sm">
              {card.metrica}: {card.valor_atual} de {card.valor_alvo}
              {card.is_porcentagem ? '%' : ''}
            </p>
            {/* Barra de progresso da métrica */}
            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-1">
              <div 
                className={`h-2.5 rounded-full ${getProgressColor(metricProgress)}`}
                style={{ width: `${metricProgress}%` }}
              ></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExperimentCard;