// calculations.js

// Calcula o progresso do prazo baseado nas datas de início e fim
export const calculateDeadlineProgress = (startDate, endDate) => {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    const total = end - start;
    const elapsed = now - start;
    return Math.min(Math.max((elapsed / total) * 100, 0), 100);
  };
  
  // Calcula os dias restantes até o prazo final
  export const calculateRemainingDays = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const remaining = end - now;
    return Math.ceil(remaining / (1000 * 60 * 60 * 24));
  };
  
  // Calcula o progresso da métrica baseado nos valores atual e alvo
  export const calculateMetricProgress = (currentValue, targetValue, isPercentage) => {
    if (isPercentage) {
      return Math.min(Math.max((currentValue / targetValue) * 100, 0), 100);
    } else {
      return Math.min(Math.max((currentValue / targetValue) * 100, 0), 100);
    }
  };