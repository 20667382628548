import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import '../css/markdown-styles.css';

const AIChecking = forwardRef(({ cardData, projectId }, ref) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [experimentGrade, setExperimentGrade] = useState(localStorage.getItem(`experimentGrade_${cardData.id}`) || null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const fetchChatHistory = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/historico-chat/${cardData.id}`);
      if (response.data && response.data.messages) {
        let parsedMessages;
        if (typeof response.data.messages === 'string') {
          try {
            parsedMessages = JSON.parse(response.data.messages);
          } catch (parseError) {
            console.error('Erro ao fazer parse das mensagens:', parseError);
            parsedMessages = [];
          }
        } else if (Array.isArray(response.data.messages)) {
          parsedMessages = response.data.messages;
        } else {
          console.error('Formato de mensagens inválido:', response.data.messages);
          parsedMessages = [];
        }
        setMessages(parsedMessages);
        setThreadId(response.data.thread_id);
      } else {
        setMessages([]);
        setThreadId(null);
      }
    } catch (error) {
      console.error('Erro ao buscar histórico de chat:', error);
      setMessages([]);
      setThreadId(null);
    }
  }, [cardData.id, API_URL]);

  useEffect(() => {
    fetchChatHistory();
  }, [cardData.id, fetchChatHistory]);

  const saveChatHistory = async (messagesToSave, currentThreadId) => {
    try {
      if (!currentThreadId || messagesToSave.length === 0) {
        console.log('Nenhuma mensagem para salvar ou thread_id ausente');
        return;
      }
      await axios.post(`${API_URL}/historico-chat`, {
        projeto_id: projectId,
        card_id: cardData.id,
        thread_id: currentThreadId,
        messages: JSON.stringify(messagesToSave)
      });
      console.log('Histórico de chat salvo com sucesso');
    } catch (error) {
      console.error('Erro ao salvar histórico de chat:', error);
    }
  };

  const updateExperimentGrade = async (newGrade) => {
    try {
      await axios.put(`${API_URL}/experimentos/${cardData.id}`, {
        ...cardData,
        nota: newGrade
      });
      console.log('Nota do experimento atualizada com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar a nota do experimento:', error);
    }
  };

  const handleAnalyzeExperiment = async (experimentData) => {
    console.log('handleAnalyzeExperiment chamado', experimentData);
    setIsAnalyzing(true);
    setIsLoading(true);
    const newMessage = { role: 'user', content: 'Analise meu experimento' };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    try {
      const response = await axios.post(`${API_URL}/check-experiment`, {
        message: 'Analise meu experimento',
        threadId: threadId,
        cardData: experimentData
      });

      const aiResponse = response.data.aiResponse;
      const newAiMessage = { role: 'assistant', content: aiResponse };
      const finalMessages = [...updatedMessages, newAiMessage];
      setMessages(finalMessages);
      
      if (!threadId) {
        setThreadId(response.data.threadId);
      }

      const gradeMatch = aiResponse.match(/NOTA FINAL → (\d+)/);
      if (gradeMatch) {
        const newGrade = parseInt(gradeMatch[1]);
        setExperimentGrade(newGrade);
        localStorage.setItem(`experimentGrade_${cardData.id}`, newGrade);
        updateExperimentGrade(newGrade);
      }

      await saveChatHistory(finalMessages, response.data.threadId);
    } catch (error) {
      console.error('Erro ao analisar experimento:', error);
      setMessages(prev => [...prev, { role: 'error', content: 'Ocorreu um erro ao analisar o experimento.' }]);
    } finally {
      setIsLoading(false);
      setIsAnalyzing(false);
    }
  };

  const handleSendMessage = async (inputData) => {
    if (inputData.action === 'analyze') {
      await handleAnalyzeExperiment(cardData);
    } else {
      if (!inputData.trim()) return;

      setIsLoading(true);
      const newMessage = { role: 'user', content: inputData };
      const updatedMessages = [...messages, newMessage];
      setMessages(updatedMessages);
      setInputMessage('');

      try {
        const response = await axios.post(`${API_URL}/check-experiment`, {
          message: inputData,
           threadId: threadId,
          cardData: threadId ? null : cardData
        });

        const aiResponse = response.data.aiResponse;
        const newAiMessage = { role: 'assistant', content: aiResponse };
        const finalMessages = [...updatedMessages, newAiMessage];
        setMessages(finalMessages);
        
        // Se não havia thread_id, agora temos um novo
        if (!threadId) {
          setThreadId(response.data.threadId);
        }

        // Extrair a nota do experimento
        const gradeMatch = aiResponse.match(/NOTA FINAL → (\d+)/);
        if (gradeMatch) {
          setExperimentGrade(parseInt(gradeMatch[1]));
        }

        // Salvar o histórico de chat atualizado
        await saveChatHistory(finalMessages, response.data.threadId);
      } catch (error) {
        console.error('Erro ao enviar mensagem para a IA:', error);
        setMessages(prev => [...prev, { role: 'error', content: 'Ocorreu um erro ao processar sua mensagem.' }]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleAnalyzeExperiment
  }));

  return (
    <div className="ai-checking-container w-full h-full flex flex-col bg-gray-100 rounded-lg shadow-md pt-10">
      <div ref={chatContainerRef} className="flex-grow overflow-y-auto p-4 space-y-4" style={{ maxHeight: 'calc(100vh - 300px)' }}>
        {messages.map((message, index) => (
          <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div className={`max-w-full p-3 rounded-lg ${
              message.role === 'user' ? 'bg-blue-500 text-white' : 
              message.role === 'assistant' ? 'bg-white text-gray-800' : 'bg-red-500 text-white'
            }`}>
              {message.role === 'assistant' ? (
                <ReactMarkdown className="markdown-content text-left">{message.content}</ReactMarkdown>
              ) : (
                message.content
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="p-4 bg-white border-t">
        <div className="flex space-x-2">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Digite sua mensagem..."
            className="flex-grow p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage(inputMessage)}
          />
          <button
            onClick={() => handleSendMessage(inputMessage)}
            disabled={isLoading}
            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300"
          >
            {isLoading ? 'Enviando...' : 'Enviar'}
          </button>
        </div>
      </div>
    </div>
  );
});

export default AIChecking;