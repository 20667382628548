// src/hooks/useProjects.js
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const useProjects = () => {
  console.log('useProjects hook called');
  const [projects, setProjects] = useState({});
  const [currentProject, setCurrentProject] = useState('');

  const loadProjects = useCallback(async () => {
    console.log('loadProjects called');
    try {
      console.log('Fetching projects from API');
      const projectsResponse = await axios.get(`${API_URL}/projetos`);
      console.log('Projects API response:', projectsResponse.data);
      
      const projectsMap = projectsResponse.data.reduce((acc, project) => {
        acc[project.nome] = {
          backlog: [],
          todo: [],
          running: [],
          done: []
        };
        return acc;
      }, {});

      console.log('Setting projects:', projectsMap);
      setProjects(projectsMap);
      
      if (!currentProject && Object.keys(projectsMap).length > 0) {
        const firstProject = Object.keys(projectsMap)[0];
        setCurrentProject(firstProject);
        console.log('Setting current project to:', firstProject);
      }
    } catch (error) {
      console.error('Erro ao carregar projetos:', error);
    }
  }, [currentProject]);

  useEffect(() => {
    console.log('useEffect in useProjects triggered');
    loadProjects();
  }, [loadProjects]);

  const saveProject = async (projectName, columns) => {
    try {
      console.log('Salvando projeto:', projectName);
      console.log('Colunas:', columns);
      // Primeiro, salve o projeto se ele não existir
      let projectResponse = await axios.get(`${API_URL}/projetos?nome=${projectName}`);
      if (projectResponse.data.length === 0) {
        projectResponse = await axios.post(`${API_URL}/projetos`, { nome: projectName });
        console.log('Novo projeto salvo:', projectResponse.data);
      }

      // Depois, salve ou atualize os experimentos
      for (const estagio_funil in columns) {
        for (const experiment of columns[estagio_funil]) {
          if (!experiment.name) {
            console.error('Tentativa de salvar experimento sem nome');
            continue;
          }
          const experimentPayload = {
            ...experiment,
            projeto: projectName,
            estagio_funil: estagio_funil
          };
          if (experiment.id) {
            await axios.put(`${API_URL}/experimentos/${experiment.id}`, experimentPayload);
          } else {
            const response = await axios.post(`${API_URL}/experimentos`, experimentPayload);
            experiment.id = response.data.id;
          }
        }
      }
      await loadProjects(); // Recarrega todos os projetos após salvar
    } catch (error) {
      console.error('Erro ao salvar projeto:', error);
    }
  };

  const loadProject = (projectName) => {
    setCurrentProject(projectName);
    return projects[projectName] || {
      backlog: [],
      todo: [],
      running: [],
      done: []
    };
  };

  const deleteProject = async (projectName) => {
    try {
      const experimentsToDelete = Object.values(projects[projectName]).flat();
      for (const experiment of experimentsToDelete) {
        await axios.delete(`${API_URL}/experimentos/${experiment.id}`);
      }
      setProjects(prevProjects => {
        const { [projectName]: deletedProject, ...remainingProjects } = prevProjects;
        return remainingProjects;
      });
      if (currentProject === projectName) {
        setCurrentProject('');
      }
    } catch (error) {
      console.error('Erro ao deletar projeto:', error);
    }
  };

  return { projects, currentProject, saveProject, loadProject, setCurrentProject, deleteProject };
};