import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const AcompanhamentosETodos = ({ projectId }) => {
  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [newMeeting, setNewMeeting] = useState({ date: '', type: 'Weekly', notes: '' });

  useEffect(() => {
    if (projectId) {
      fetchMeetings();
    }
  }, [projectId]);

  const fetchMeetings = async () => {
    try {
      const response = await axios.get(`${API_URL}/meetings?projectId=${projectId}`);
      setMeetings(response.data);
    } catch (error) {
      console.error('Erro ao buscar reuniões:', error);
    }
  };

  const handleAddMeeting = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/meetings`, { ...newMeeting, projectId });
      setMeetings([...meetings, response.data]);
      setNewMeeting({ date: '', type: 'Weekly', notes: '' });
      alert('Reunião adicionada com sucesso!');
    } catch (error) {
      console.error('Erro ao adicionar reunião:', error);
      alert('Erro ao adicionar reunião. Por favor, tente novamente.');
    }
  };

  const handleSelectMeeting = (meeting) => {
    setSelectedMeeting(meeting);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Acompanhamento & to-dos - {projectId}</h2>
      
      <div className="flex">
        {/* Coluna esquerda */}
        <div className="w-1/3 pr-4">
          <button
            onClick={() => setSelectedMeeting(null)}
            className="w-full mb-4 bg-blue-500 text-white px-4 py-2 rounded"
          >
            Adicionar reunião
          </button>
          
          <div className="bg-purple-200 p-2 mb-2">
            <h3 className="font-bold">Data - Tipo</h3>
          </div>
          
          {meetings.map(meeting => (
            <div
              key={meeting.id}
              onClick={() => handleSelectMeeting(meeting)}
              className="cursor-pointer p-2 hover:bg-gray-100"
            >
              {new Date(meeting.date).toLocaleDateString()} - {meeting.type}
            </div>
          ))}
        </div>
        
        {/* Coluna direita */}
        <div className="w-2/3 pl-4">
          {selectedMeeting ? (
            <div>
              <h3 className="text-xl font-bold mb-2">
                Reunião de {new Date(selectedMeeting.date).toLocaleDateString()}
              </h3>
              <p>{selectedMeeting.notes}</p>
            </div>
          ) : (
            <form onSubmit={handleAddMeeting} className="space-y-4">
              <div>
                <label className="block">Data:</label>
                <input
                  type="date"
                  value={newMeeting.date}
                  onChange={(e) => setNewMeeting({...newMeeting, date: e.target.value})}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="block">Tipo:</label>
                <select
                  value={newMeeting.type}
                  onChange={(e) => setNewMeeting({...newMeeting, type: e.target.value})}
                  className="w-full p-2 border rounded"
                >
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                </select>
              </div>
              <div>
                <label className="block">Notas:</label>
                <textarea
                  value={newMeeting.notes}
                  onChange={(e) => setNewMeeting({...newMeeting, notes: e.target.value})}
                  className="w-full p-2 border rounded h-40"
                  required
                />
              </div>
              <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
                Salvar Reunião
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcompanhamentosETodos;
