import React, { useState, useCallback, useRef } from 'react';
import ExperimentCard from './ExperimentCard';
import CardForm from './CardForm';
import AIChecking from './AIChecking';
import axios from 'axios';

// Configuração da URL da API
const API_URL = process.env.REACT_APP_API_URL;

// Componente principal do Kanban Board
const KanbanBoard = ({ columns, setColumns, currentProject, saveProject }) => {
  // Estados locais
  const [showForm, setShowForm] = useState(false);
  const [editingCard, setEditingCard] = useState(null);
  const aiCheckingRef = useRef(null);
  const [showDeadlinesAndMetrics, setShowDeadlinesAndMetrics] = useState(true);

  // Função para lidar com a submissão do card (adição ou edição)
  const handleCardSubmit = useCallback(async (cardData) => {
    if (cardData.action === 'analyze') {
      console.log('handleCardSubmit chamado com ação analyze', cardData);
      if (aiCheckingRef.current) {
        await aiCheckingRef.current.handleAnalyzeExperiment(cardData);
      }
    } else {
      // Lógica existente para salvar/atualizar o card
      try {
        // Validação dos campos obrigatórios
        const requiredFields = ['name', 'descricao', 'data_inicio', 'data_fim', 'estagio_funil', 'metrica', 'valor_alvo', 'canal', 'pdm'];
        const missingFields = requiredFields.filter(field => cardData[field] === undefined || cardData[field] === '');
        if (missingFields.length > 0) {
          alert(`Por favor, preencha os seguintes campos obrigatórios: ${missingFields.join(', ')}`);
          return;
        }

        // Preparação dos dados do card
        const cardPayload = {
          ...cardData,
          projeto_id: currentProject,
          is_porcentagem: cardData.is_porcentagem || false
        };

        console.log('Dados sendo enviados para o backend:', cardPayload);

        // Envio dos dados para o backend (PUT para edição, POST para adição)
        let updatedCard;
        if (cardData.id) {
          const response = await axios.put(`${API_URL}/experimentos/${cardData.id}`, cardPayload);
          updatedCard = response.data;
        } else {
          const response = await axios.post(`${API_URL}/experimentos`, cardPayload);
          updatedCard = response.data;
        }

        console.log('Resposta do backend:', updatedCard);

        // Atualização do estado das colunas
        setColumns(prevColumns => {
          const newColumns = { ...prevColumns };
          
          // Remover o card de todas as colunas existentes
          Object.keys(newColumns).forEach(columnId => {
            newColumns[columnId] = newColumns[columnId].filter(card => card.id !== updatedCard.id);
          });

          // Adicionar o card atualizado à coluna correta
          const targetColumn = updatedCard.estagio_funil;
          if (!newColumns[targetColumn]) {
            newColumns[targetColumn] = [];
          }
          newColumns[targetColumn].push(updatedCard);

          return newColumns;
        });

        // Atualizar o editingCard com os novos dados
        setEditingCard(updatedCard);

        // Forçar uma atualização do componente
        setColumns(prev => ({ ...prev }));

        // Adicionar uma mensagem de sucesso
        alert('Alterações salvas com sucesso!');
      } catch (error) {
        console.error('Erro ao salvar experimento:', error);
        if (error.response) {
          console.error('Resposta de erro do servidor:', error.response.data);
          alert(`Erro ao salvar experimento: ${error.response.data.error || 'Erro desconhecido'}`);
        } else if (error.request) {
          console.error('Sem resposta do servidor:', error.request);
          alert('Erro ao salvar experimento: Sem resposta do servidor');
        } else {
          console.error('Erro na requisição:', error.message);
          alert(`Erro ao salvar experimento: ${error.message}`);
        }
      }
    }
  }, [currentProject, setColumns]);

  // Função para lidar com a edição de um card
  const handleEdit = async (card) => {
    try {
      console.log('Tentando editar card:', card);
      if (!card || !card.id) {
        console.error('Card inválido ou sem ID');
        alert('Erro ao editar: Card inválido');
        return;
      }

      // Buscar dados atualizados do card no backend
      const response = await axios.get(`${API_URL}/experimentos/${card.id}`);
      const cardData = response.data;
      console.log('Dados do card recebidos:', cardData);

      if (!cardData) {
        console.error('Nenhum dado recebido do backend');
        alert('Erro ao carregar dados do card');
        return;
      }

      // Configurar o estado para edição
      setEditingCard(cardData);
      setShowForm(true);
    } catch (error) {
      console.error('Erro ao buscar dados do card:', error);
      if (error.response && error.response.status === 404) {
        alert('Card não encontrado. Ele pode ter sido excluído.');
        // Remover o card das colunas se ele não existir mais no backend
        setColumns(prev => {
          const updatedColumns = { ...prev };
          Object.keys(updatedColumns).forEach(columnId => {
            updatedColumns[columnId] = updatedColumns[columnId].filter(c => c.id !== card.id);
          });
          return updatedColumns;
        });
      } else {
        alert('Erro ao carregar dados do card. Por favor, tente novamente.');
      }
    }
  };

  // Função para lidar com a exclusão de um card
  const handleDelete = async (cardToDelete) => {
    try {
      await axios.delete(`${API_URL}/experimentos/${cardToDelete.id}`);
      setColumns(prev => {
        const updatedColumns = { ...prev };
        Object.keys(updatedColumns).forEach(columnId => {
          updatedColumns[columnId] = updatedColumns[columnId].filter(card => card.id !== cardToDelete.id);
        });
        
        // Salvar o projeto após deletar o card
        saveProject(currentProject, updatedColumns);
        
        return updatedColumns;
      });
      setShowForm(false); // Fecha o modal após a exclusão
    } catch (error) {
      console.error('Erro ao deletar experimento:', error);
      alert('Erro ao deletar experimento. Por favor, tente novamente.');
    }
  };

  // Função para duplicar um card
  const handleDuplicate = (cardToDuplicate) => {
    const newCard = { ...cardToDuplicate, id: Date.now().toString() };
    setColumns(prev => ({
      ...prev,
      backlog: [...prev.backlog, newCard]
    }));
  };

  // Renderização do componente
  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Experimentos - Resumo Executivo</h1>
        
        {/* Adicione o checkbox ao lado esquerdo do botão */}
        <div className="flex items-center space-x-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={showDeadlinesAndMetrics}
              onChange={() => setShowDeadlinesAndMetrics(!showDeadlinesAndMetrics)}
              className="mr-2"
            />
            <span>Prazos e Métricas</span>
          </label>
          
          <button
            onClick={() => {
              setEditingCard(null);
              setShowForm(true);
            }}
            className={`px-4 py-2 rounded ${
              currentProject
                ? 'bg-blue-500 text-white hover:bg-blue-600'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
            disabled={!currentProject}
          >
            {currentProject ? 'Adicionar Card' : 'Selecione um projeto para adicionar cards'}
          </button>
        </div>
      </div>
      
      {/* Renderização das colunas do Kanban */}
      <div className="flex justify-between w-full pb-4 space-x-4">
        {Object.entries(columns).map(([columnId, cards]) => (
          <div
            key={columnId}
            className="w-1/5 p-4 rounded-lg bg-gray-100"
          >
            <h2 className="text-lg font-bold mb-4 capitalize">{columnId}</h2>
            <div className="space-y-4">
              {cards.map((card) => (
                card && card.id ? (
                  <div key={card.id} className="p-2 rounded-lg">
                    <ExperimentCard
                      card={card}
                      onEdit={handleEdit}
                      onDelete={() => handleDelete(card)}
                      onDuplicate={() => handleDuplicate(card)}
                      showDeadlinesAndMetrics={showDeadlinesAndMetrics}
                    />
                  </div>
                ) : null
              ))}
            </div>
          </div>
        ))}
      </div>

      {showForm && (
        <CardForm
          onSubmit={handleCardSubmit}
          onCancel={() => setShowForm(false)}
          onEdit={handleEdit}
          onDelete={handleDelete}
          initialData={editingCard || {}}
        >
          <AIChecking ref={aiCheckingRef} cardData={editingCard || {}} projectId={currentProject} />
        </CardForm>
      )}
    </div>
  );
};

export default KanbanBoard;