import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { MdClose } from "react-icons/md";
import HelpModal from './HelpModal';
import { canalOptions } from '../utils/canalOptions';

const API_URL = process.env.REACT_APP_API_URL;

const CardForm = ({ onSubmit, onCancel, onEdit, onDelete, initialData = {}, children }) => {
  const [cardData, setCardData] = useState(initialData);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isEditing, setIsEditing] = useState(!initialData.id);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  useEffect(() => {
    setCardData(initialData);
    setIsFormChanged(false);
  }, [initialData]);

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setCardData(prevData => ({ ...prevData, [e.target.name]: value }));
    setIsFormChanged(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(cardData);
    setIsFormChanged(false);
  };

  const handleAnalyze = useCallback(async () => {
    console.log('handleAnalyze chamado', cardData);
    setIsAnalyzing(true);
    try {
      const response = await axios.get(`${API_URL}/experimentos/${cardData.id}`);
      const experimentData = response.data;
      await onSubmit({ ...experimentData, action: 'analyze' });
    } catch (error) {
      console.error('Erro ao buscar dados do experimento:', error);
      alert('Erro ao analisar experimento. Por favor, tente novamente.');
    } finally {
      setIsAnalyzing(false);
    }
  }, [cardData.id, onSubmit]);

  const handleEdit = () => {
    if (initialData.id) {
      setIsEditing(!isEditing);
      setIsFormChanged(false);
    }
  };

  const isSaveDisabled = initialData.id ? !isFormChanged : false;
  const isAnalyzeDisabled = isFormChanged;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-11/12 max-w-7xl shadow-lg rounded-md bg-white">
        <button 
          onClick={onCancel}
          className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600 focus:outline-none"
        >
          <MdClose size={24} />
        </button>
        <div className="mt-3 text-left flex justify-between items-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {initialData.id ? `${initialData.name} ${initialData.nota ? `(Nota: ${initialData.nota})` : ''}` : "Adicionar Novo Card"}
          </h3>
          {initialData.id && (
            <div className="pr-8">
              <button
                onClick={handleEdit}
                className="mr-2 px-2 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                {isEditing ? "Cancelar Edição" : "Editar"}
              </button>
              <button
                onClick={async () => {
                  if (window.confirm(`Tem certeza que deseja deletar o card "${initialData.name}"?`)) {
                    await onDelete(initialData);
                    onCancel();
                  }
                }}
                className="px-2 py-1 text-sm bg-red-500 text-white rounded hover:bg-red-600"
              >
                Excluir
              </button>
            </div>
          )}
        </div>
        <div className="mt-2 flex">
          <div className="w-1/2 pr-4 border-r">
            <form onSubmit={handleSubmit} className="text-left">
              {/* Campo: Nome do Experimento */}
              <div className="mb-2">
                <label htmlFor="name" className="block text-left text-sm font-medium text-gray-700">Nome:</label>
                <input
                  id="name"
                  name="name"
                  value={cardData.name || ''}
                  onChange={handleChange}
                  placeholder="Nome do Experimento"
                  className={`w-full p-2 border text-sm rounded ${!isEditing ? 'bg-gray-100' : ''}`}
                  required
                  readOnly={!isEditing}
                />
              </div>
              {/* Campo: PDM - Persona Dor e momento */}
              <div className="mb-2">
                <label htmlFor="pdm" className="block text-left text-sm font-medium text-gray-700">Persona, DOR e Momento:</label>
                <textarea
                  id="pdm"
                  name="pdm"
                  value={cardData.pdm || ''}
                  onChange={handleChange}
                  placeholder="Descreva a Persona, DOR e Momento"
                  className={`w-full p-2 border rounded resize-none text-sm h-24 ${!isEditing ? 'bg-gray-100' : ''}`}
                  readOnly={!isEditing}
                />
              </div>
              {/* Campo: Descrição do Experimento */}
              <div className="mb-2">
                <label htmlFor="descricao" className="block text-left text-sm font-medium text-gray-700">Descrição / Hipótese:</label>
                <textarea
                  id="descricao"
                  name="descricao"
                  value={cardData.descricao || ''}
                  onChange={handleChange}
                  placeholder="Descreva a hipótese do experimento"
                  className={`w-full p-2 border rounded resize-none text-sm h-40 ${!isEditing ? 'bg-gray-100' : ''}`}
                  readOnly={!isEditing}
                />
              </div>
              {/* Campos: Estágio do Funil e Canal */}
              <div className="mb-2 flex space-x-2">
                <div className="flex-1">
                  <label htmlFor="estagio_funil" className="block text-left text-sm font-medium text-gray-700">Estágio do Funil:</label>
                  <select
                    id="estagio_funil"
                    name="estagio_funil"
                    value={cardData.estagio_funil || ''}
                    onChange={handleChange}
                    className={`w-full p-2 border rounded ${!isEditing ? 'bg-gray-100' : ''}`}
                    required
                    disabled={!isEditing}
                  >
                    <option value="" disabled>Selecione o estágio</option>
                    <option value="backlog">Backlog</option>
                    <option value="todo">To Do</option>
                    <option value="running">Running</option>
                    <option value="analysing">Analysing</option>
                    <option value="done">Done</option>
                  </select>
                </div>
                <div className="flex-1">
                  <label htmlFor="canal" className="block text-left text-sm font-medium text-gray-700 flex items-center">
                    Canal:
                    <svg
                      onClick={() => setIsHelpModalOpen(true)}
                      className="h-5 w-5 text-gray-400 ml-1 cursor-pointer"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                    </svg>
                  </label>
                  <select
                    id="canal"
                    name="canal"
                    value={cardData.canal || ''}
                    onChange={handleChange}
                    className={`w-full p-2 pr-8 border rounded ${!isEditing ? 'bg-gray-100' : ''}`}
                    required
                    disabled={!isEditing}
                  >
                    <option value="">Selecione um canal</option>
                    {canalOptions.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Campo: Data de Início e Fim */}
              <div className="mb-2 flex space-x-2">
                <div className="flex-1">
                  <label htmlFor="data_inicio" className="block text-left text-sm font-medium text-gray-700">Início do experimento:</label>
                  <input
                    id="data_inicio"
                    name="data_inicio"
                    type="date"
                    value={cardData.data_inicio || ''}
                    onChange={handleChange}
                    className={`w-full p-2 border rounded ${!isEditing ? 'bg-gray-100' : ''}`}
                    required
                    disabled={!isEditing}
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="data_fim" className="block text-left text-sm font-medium text-gray-700">Fim do experimento:</label>
                  <input
                    id="data_fim"
                    name="data_fim"
                    type="date"
                    value={cardData.data_fim || ''}
                    onChange={handleChange}
                    className={`w-full p-2 border rounded ${!isEditing ? 'bg-gray-100' : ''}`}
                    required
                    disabled={!isEditing}
                  />
                </div>
              </div>
              {/* Campos: Nome da Métrica e Valor Meta */}
              <div className="mb-2 flex space-x-2">
                <div className="flex-1">
                  <label htmlFor="metrica" className="block text-left text-sm font-medium text-gray-700">Nome Métrica:</label>
                  <input
                    id="metrica"
                    name="metrica"
                    value={cardData.metrica || ''}
                    onChange={handleChange}
                    placeholder="Nome da Métrica"
                    className={`w-full p-2 border rounded ${!isEditing ? 'bg-gray-100' : ''}`}
                    required
                    readOnly={!isEditing}
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="valor_alvo" className="block text-left text-sm font-medium text-gray-700">Valor Meta:</label>
                  <input
                    id="valor_alvo"
                    name="valor_alvo"
                    type="number"
                    value={cardData.valor_alvo || ''}
                    onChange={handleChange}
                    placeholder="Valor Meta"
                    className={`w-full p-2 border rounded ${!isEditing ? 'bg-gray-100' : ''}`}
                    required
                    readOnly={!isEditing}
                  />
                </div>
              </div>
              {/* Campo: Valor Meta Atual */}
              <div className="mb-2">
                <label htmlFor="valor_atual" className="block text-left text-sm font-medium text-gray-700">Valor Meta Atual:</label>
                <input
                  id="valor_atual"
                  name="valor_atual"
                  type="number"
                  value={cardData.valor_atual === undefined ? '' : cardData.valor_atual}
                  onChange={handleChange}
                  placeholder="Valor Meta Atual"
                  className={`w-full p-2 border rounded ${!isEditing ? 'bg-gray-100' : ''}`}
                  readOnly={!isEditing}
                />
              </div>
              {/* Checkbox: Métrica é Porcentagem */}
              <div className="flex items-center mb-2">
                <input
                  id="is_porcentagem"
                  name="is_porcentagem"
                  type="checkbox"
                  checked={cardData.is_porcentagem || false}
                  onChange={handleChange}
                  className={`mr-2 ${!isEditing ? 'bg-gray-100' : ''}`}
                  disabled={!isEditing}
                />
                <label htmlFor="is_porcentagem" className="text-sm font-medium text-gray-700">Métrica é uma porcentagem?</label>
              </div>
              {/* Botões de Ação */}
              <div className="items-center px-4 py-3 flex space-x-2">
                <button
                  type="submit"
                  disabled={!isEditing || isSaveDisabled}
                  className={`flex-1 px-4 py-2 text-white text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 ${
                    isSaveDisabled
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-blue-500 hover:bg-blue-700'
                  }`}
                >
                  {initialData.id ? "Salvar Alterações" : "Adicionar"}
                </button>
                <button
                type="button"
                onClick={handleAnalyze}
                disabled={isAnalyzeDisabled || isAnalyzing}
                className={`flex-1 px-4 py-2 text-white text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 ${
                  isAnalyzeDisabled || isAnalyzing
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-green-500 hover:bg-green-700'
                }`}
              >
                {isAnalyzing ? 'Analisando...' : 'Analisar Experimento'}
              </button>
              </div>
            </form>
          </div>
          <div className="w-1/2 pl-4">
            {children}
          </div>
        </div>
      </div>
      <HelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        canalOptions={canalOptions}
      />
    </div>
  );
};

export default CardForm;